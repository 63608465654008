import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { OrdersService, ConfigService, AuthService, NewOrderService, UsermessageService, SubscribeService, TagsService, ProgressBarService, AwsS3Service, RoutingStateService, QuoterequestService, FileService, UsersService,OrganizationService, AbstractorService } from 'app/services';
import { CONSTANTS } from '../../app.constants';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import * as _ from 'underscore';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import * as momentTimeZone from 'moment-timezone';
import { Location} from '@angular/common';
import { MessageComponent } from '../../dialog/message/message.component';
import { CancelOrderComponent } from '../../dialog/cancel-order/cancel-order.component';
import { QuoteCompleteComponent } from 'app/dialog/quote-complete/quote-complete.component';
import { ChangeOrderStatusComponent } from 'app/dialog/change-order-status/change-order-status.component';
import { ConfirmComponent } from 'app/dialog/confirm/confirm.component';
import { ConvertOrderComponent } from 'app/dialog/convert-order/convert-order/convert-order.component';
import { SuccessComponent } from 'app/dialog/success/success.component';
import { environment } from 'environments/environment';
import { ScreenOrderComponent } from 'app/dialog/screen-order/screen-order.component';
import { MatDialog } from '@angular/material/dialog';
import { DocCategoryComponent } from 'app/doc-category/doc-category.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AbstractorQuoteComponent } from '../../dialog/abstractor-quote/abstractor-quote.component';
import { AbsQuoteMessageComponent } from '../../dialog/abs-quote-message/abs-quote-message.component';
import { NewAbsQuoteComponent } from '../../dialog/new-abs-quote/new-abs-quote.component';
import { ReviewCompleteQuoteComponent } from '../../dialog/review-complete-quote/review-complete-quote.component';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  quoteDetails : any = {};
  orderStatus: any;
  completeAddress: any;
  fullAddress: any;
  isQualiaOrder: boolean = false;
  copyMessage = '';
  timer: any = [];
  quoteId: string;
  isRushOrder: boolean;
  subscribeDetails: any = {};
  isDate: boolean = false;
  netrOnlineLink: string = 'https://publicrecords.netronline.com/state/';
  constants = CONSTANTS;
  searchTag: string = '';
  orderTags: any = [];
  subscriptionStatus: boolean;
  documents: any = [];
  adminDocs: any = [];
  clientDocs: any = [];
  newDocs: any = [];
  internalDocs: any = [];
  messages: any = [];
  availableTags: any = [];
  associatedTags: any = [];
  orderIns: any = {};
  orderNotes:any = {};
  progressBar: boolean = false;
  primaryColor = '#03a9f4';
  parentMsgId = null;
  uploadType: number;
  isChecklist: boolean = false;
  isCountyInfo: boolean = false;
  isClientInfo: boolean = false;
  isBillingInfo: boolean = false;
  isSearchInfo: boolean = false;
  isRelatedSearches: boolean = false;
  isOrderHistory: boolean = false;
  isETAChange: boolean = false;
  loadingCheckList: boolean = false;
  loadingCountyInfo: boolean = false;
  loadingSearchInfo: boolean = false;
  loadingClientInfo: boolean = false;
  loadingBillingInfo: boolean = false;
  loadingRelatedSearches: boolean = false;
  loadingOrderHistory: boolean = false;
  loadingETAChange: boolean = false;
  gSheetChecklist: any = [];
  gSheetSearchInfo: any = [];
  gSheetCountyInfo: any = {};
  gSheetClientInfo: any = {};
  gSheetBillingInfo: any = {};
  etaChangeReasons: any = [];
  ordsWithSameAddress: any = [];
  ordsWithSameParcel: any = []; // PT-3598
  ordsWithSameSubdivision: any = []; // PT-3598
  orderhistory: any = [];
  ordsWithSimilarCountyOrZip: any = [];
  ordsWithSameClient: any = [];
  selectedDocsTab: number;
  products: any;
	public paymentIsRequired: boolean = false;
  isAllowed: boolean = false;  
  documentCategories:any = [];
  coborrowerAddress: any;
  private ngUnsubscribe = new Subject();
  dataDepthYears : any;
  constructor(
    private orderService: OrdersService,
    private config:ConfigService,
    private auth: AuthService,
    private userMsg:UsermessageService,
    private matDialog:MatDialog,
    private subscribeService:SubscribeService,
    private tagService:TagsService,
    private progressServ:ProgressBarService,
    private route: ActivatedRoute,
    private s3Service: AwsS3Service,
    private router: Router,
    private location: Location,
    private QuoterequestService: QuoterequestService,
    private fileService: FileService,
    private newOrder: NewOrderService,
    private userService: UsersService,
    public dialog: MatDialog,
    public routingState:RoutingStateService,
    private organizationService: OrganizationService,
    private abstractorService: AbstractorService
  ) {
    this.config.setMySidebar(false);
   }
  ngOnDestroy(): void {    
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.auth.refreshToken();
      if(this.checkAdminType()) {
        if(this.isCompAdmin()) this.executeOninit()
        else {
          this.route.params.subscribe(params =>
          this.orderService.checkOrderTags(params['quoteId'], this.auth.getUserId())
          .subscribe((status) => {
            if (!status.approved) {
              if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
              else this.router.navigate(['/orders/new']);
              this.openDenyPermission();
          } else {
              this.route.params.subscribe(params => {
              this.QuoterequestService.getQuoteDetails( params['quoteId'])
              .subscribe((data) => {
              if(data){
                this.executeOninit()
              }
              else this.router.navigate(['/orders/new']) 
              })
            })
          }
          }, (err) => {
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
            if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
            else this.router.navigate(['/orders/new']);
          }))
        }
      } else this.router.navigate(['/orders/new']) 
    this.orderService.documentCategories$.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      this.documentCategories = data;
      this.adminDocs = this.adminDocs.map((item) => {
        if(item.Doc_Cat_ID){
          let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
          item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
        }
        else item.Category = 'Click to categorize'
        return item;
      });
      this.internalDocs = this.internalDocs.map((item) => {
        if(item.Doc_Cat_ID){
          let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
          item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
        }
        else item.Category = 'Click to categorize'
        return item;
      });
    });
  }


  openDenyPermission() {
    this.matDialog.open(
      SuccessComponent,
      {data:{
        message: "You do not have sufficient permissions to view this quote. Please get in touch with your team lead to obtain access to this quote.",
        title: "Access denied"
      },
      ...this.config.getDialogOptions()
    }
    )
  }

  isCompAdmin() {
    let role = Number(this.auth.getUserRole());
    if(role == CONSTANTS.userRole.superAdmin || role == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }

  executeOninit(){
    this.progressBar = true;
    this.route.params.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.initializeProgressBar();
      this.getQuoteDetails(this.quoteId);
      this.getSubscriptionDetails(this.quoteId);
      this.getOrderTags(this.quoteId);
      this.getOrderNotes(this.quoteId);
      this.getOrderMessages(this.quoteId);
      this.getDocs(this.quoteId);
      this.getTimeline(this.quoteId);
      this.getChecklistFromGsheet(this.quoteId);
      this.auth.deleteUserNotificationbyOrderIdAndUserId(this.quoteId).subscribe(() => { });
      this.orderStatus = this.orderService.getOrderStatus();
      // this.getAllSystemDocumentsTag();
      this.uploadType = CONSTANTS.orderDocumentType.adminDocs;
      // this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
      this.showSideBarPanel();
      this.fetchUploadProgress();

      this.timer.push(setTimeout(() => {
        this.getCountyInfoFromGsheet();
        this.getClientInfoFromGsheet();
        this.getRelatedSerarchOrders();
        this.getBillingInfoFromGsheet();
        this.getSearchInfo()
        this.getOrderHistoryFromDB(this.quoteId);
        this.getETAChangeReasonsById(this.quoteId);
        this.getDataDepthInfo();
      }, 7000))
    });
    this.getProductsForCreateOrder(); 
    this.checkForPermission();
    this.orderService.getDocumentCategories().subscribe();
  }

  showSideBarPanel(){
    if(Number(this.config.getSideBarNode())) this.timer.push(setTimeout(() => this.config.setSideBarNode(this.config.getSideBarNode()), 0));
    else this.timer.push(setTimeout(() => this.config.setSideBarNode(1), 0));
  }

  getQuoteDetails(quoteId){
      this.QuoterequestService.getQuoteDetails(quoteId)
      .subscribe((data) => {
        this.quoteDetails = data
        if(this.quoteDetails.Co_Borrower_Property_ID) this.buildCoborrowerAddress(this.quoteDetails);
        this.loadOrderPriorityData(this.quoteDetails);
        this.setProgressBarStatus(this.quoteDetails['Order_Status'])
        this.buildAddress(this.quoteDetails);
      })
  }

  buildCoborrowerAddress(model) {
    this.coborrowerAddress = '';
    if (model.Co_Borrower_Property_Address_1) this.coborrowerAddress += model.Co_Borrower_Property_Address_1;    
    if (model.Co_Borrower_Property_City) {
      if (this.coborrowerAddress) this.coborrowerAddress += '\n'
      this.coborrowerAddress += model.Co_Borrower_Property_City
    }
    if (model.Co_Borrower_Property_State_Abbr) {
      if (this.coborrowerAddress) this.coborrowerAddress += ', '
      this.coborrowerAddress += model.Co_Borrower_Property_State_Abbr
    }
    if (model.Co_Borrower_Property_ZipCode) {
      if (this.coborrowerAddress) this.coborrowerAddress += ' '
      this.coborrowerAddress += model.Co_Borrower_Property_ZipCode;
    }
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      let result = !!pattern.test(str);
      if(result) return true;
      else {
        let booleanVal = false;
        try{
          let urlObj = new URL(str);
          if(urlObj) booleanVal = true;
        }
        catch (e){}
        return booleanVal;
      }
  }

  copyToClipBoard() {
    var sl = '';
    var receivedDate = '';
    var orderNo = '';
    var pippinInvoice = this.quoteDetails.Order_ID;
    var client = '';
    var customer = '';
    var propOwner = this.checkPropOwnerName();
    var propAddress = this.buildCompAddress(this.quoteDetails);
    var state = this.quoteDetails.Property_State_Abbr;
    var county = this.quoteDetails.Property_County;
    // var client = this.quoteDetails.Organazation_Name;

    var searchType = '';
    var clientETA = '';
    var pippinETA = '';
    var status = '';
    var completedDate = '';

    if (this.quoteDetails.Organization_ID && !this.checkIfSearchMaster()) {
      if (this.quoteDetails.Organization_ID == this.config.getQualiaOrgId()) this.isQualiaOrder = true;
      client = this.quoteDetails.Organization_Name;
    }
    if (!this.checkIfSearchMaster()) {
      if (this.isQualiaOrder || !this.quoteDetails.Organization_ID) customer = this.quoteDetails.Client_User_Full_Name;
      else customer = this.quoteDetails.Organization_Name;
    }
    if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.received) {
      status = 'RECEIVED';
    } else if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.cancelled) {
      status = 'CANCELLED';
    } else if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.assigned) {
      status = 'ASSIGNED';
    } else if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.confirmed) {
      status = 'CONFIRMED';
    } else if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.processing) {
      status = 'PROCESSING';
    } else if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.quotecompleted) {
      status = 'COMPLETED';
    }
    if (this.quoteDetails.Order_Creation_Date) receivedDate = new Date(this.quoteDetails.Order_Creation_Date).toLocaleDateString("en-US", { timeZone: "America/New_York" });
    if (this.quoteDetails.Order_Completion_Date && this.quoteDetails.Order_Status == CONSTANTS.orderStatus.quotecompleted) {
      completedDate = new Date(this.quoteDetails.Order_Completion_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
      completedDate = completedDate.replace(',', '');
    }

    if (this.quoteDetails.Order_ETA) {
      clientETA = new Date(this.quoteDetails.Order_ETA).toLocaleString("en-US", { timeZone: "America/New_York" });
      clientETA = clientETA.replace(',', '');
    }

    var dataToClip = sl + '\t'
      + receivedDate + '\t'
      + orderNo + '\t'
      + pippinInvoice + '\t'
      + client + '\t'
      + customer + '\t'
      + propOwner + '\t'
      + propAddress + '\t'
      + state + '\t'
      + county + '\t'
      + searchType + '\t'
      + clientETA + '\t' + '\t' + '\t' + '\t' + '\t' + '\t' + '\t' + '\t' + '\t'
      + status + '\t' + '\t' + '\t' + '\t'
      + completedDate + '\t';

    return dataToClip;
  }

  checkIfExternalSearcher(clientRef) {
    if (this.checkIfNotExternalSM()) return clientRef;
    else return '';
  }

  checkPropOwnerName() {
    if (this.quoteDetails.Property_First_Name) return this.quoteDetails.Property_First_Name;
    else return '';
  }

  afterCopy() {
    this.copyMessage = "Quote details copied to clipboard(Excel format).";
    this.timer.push(setTimeout(() => this.copyMessage = "", 3000));
  }

  buildCompAddress(model) {
    this.fullAddress = '';
    if (model.Property_Address_1) this.fullAddress += model.Property_Address_1
    if (model.Property_Address_2) {
      if (this.fullAddress) this.fullAddress += ' '
      this.fullAddress += model.Property_Address_2
    }
    if (model.Property_City) {
      if (this.fullAddress) this.fullAddress += ', '
      this.fullAddress += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (this.fullAddress) this.fullAddress += ', '
      this.fullAddress += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (this.fullAddress) this.fullAddress += ' '
      this.fullAddress += model.Property_ZipCode
    }
    return this.fullAddress;
  }

  copyQuoteDetails() {
    var fileId = 'Quote#		    : ' + this.quoteDetails.Order_ID;
    var ownerName = 'Owner Name	    : ' + '';
    var productOrdered = 'Product Type        : ' + ''
    var address = 'Address		    : ' + this.buildCompAddress(this.quoteDetails);
    var county = 'County		    : ' + this.quoteDetails.Property_County;
    // var client_Name = 'County		    : ' + this.quoteDetails.Organazation_Name;

    var gtd = 'GTD		    : ' + '';
    var parcelId = 'Parcel ID	    : ' + this.checkIfDataAvailable(this.quoteDetails.Order_Parcel);
    var legal = 'Legal 	            : ' + '';
    var borrower = 'Borrower 	    : ' + this.checkIfDataAvailable(this.quoteDetails.Order_Buyer);
    var searchedName = 'Searched Name	    : ' + '';
    var dashedLine = '=========================================================';
    var headerColumnName = 'Doc Name	Rec Date	Book/Page	Instr';

    var dataToCilpboard = fileId + '\n\n'
      + ownerName + '\n\n'
      + productOrdered + '\n\n'
      + address + '\n\n'
      + county + '\n\n'
      + gtd + '\n\n'
      + parcelId + '\n\n'
      + legal + '\n\n'
      + borrower + '\n\n'
      + searchedName + '\n\n'
      + dashedLine + '\n\n'
      + headerColumnName + '\n\n'
      + dashedLine + '\n\n'
    return dataToCilpboard;
  }

  checkIfDataAvailable(data) {
    if (data) return data;
    else return '';
  }

  afterOrdDetailsCopy() {
    this.copyMessage = "Quote details copied(Search note format).";
    this.timer.push(setTimeout(() => this.copyMessage = "", 3000));
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  userAction(event) {
    this.orderService.setPriorityForRushOrders(this.quoteId, this.isRushOrder)
      .subscribe((details) => {
        if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderPriority);
        this.isRushOrder = !this.isRushOrder;
      })
  }

  checkForStatus() {
    if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.quotecompleted || this.quoteDetails.Order_Status == CONSTANTS.orderStatus.cancelled) return true;
    else return false;
  }

  subscribeAction(event) {
    if (event.checked) this.subscribeOrder();
    else this.unSubscribeOrder();
  }

  subscribeOrder() {
    this.subscribeService.subscribeOrderToAdmin(this.quoteId)
      .subscribe((subscribed) => {
        if (subscribed) {
          this.getSubscriptionDetails(this.quoteId);
          let msg = "Quote is watching for email notification."
          this.successMsgFunc(msg);
        }
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.subscribeOrder);
      }
      )
  }

  unSubscribeOrder() {
    if (!(_.isEmpty(this.subscribeDetails))) {
      return this.subscribeDetails.filter((x) => {
        if (this.auth.getUserId() == x.Subscribed_User_ID && x.Subscription_Status == CONSTANTS.subscriptionStatus.active) {
          this.subscribeService.unSubscribeOrderToAdmin(x.Order_Subscription_ID)
            .subscribe(() => {
              this.getSubscriptionDetails(this.quoteId);
              let msg = "Quote is deactivated from watch."
              this.successMsgFunc(msg);
            }, (err) => {
              console.log(err);
              this.openErrorPopup(CONSTANTS.apiErrors.unSubscribeOrder);
            })
        }
      })
    }
  }

  getEstimationTime() {
    // console.log("ESt time");
    if (this.quoteDetails && this.quoteDetails.Order_ETA && 
      (this.quoteDetails.Order_Status != this.constants.orderStatus.received 
        || this.quoteDetails.Order_Status != this.constants.orderStatus.assigned)) {
      this.isDate = true;
      // this.quoteDetails.Order_ETA = this._datePipe.transform(this.quoteDetails.Order_ETA);
      return this.quoteDetails.Order_ETA;

    }
    this.isDate = false;
    return this.quoteDetails.Order_Estimated_Time;
  }

  openNewTagModal(quoteId) {
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: quoteId }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.getOrderTags(this.quoteId);
        if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
      }
    });
  }

  modifyOrderTags(event, tag, order) {
    var isTagToAdd: boolean;
    if (event.checked) isTagToAdd = true;
    else isTagToAdd = false;
    this.orderService.modifyQuoteTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd })
      .subscribe(() => {
        this.getOrderTags(this.quoteId);
        if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
        if(isTagToAdd) this.availableTags = this.availableTags.filter(item => (item.Tag_ID !== tag.Tag_ID))
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
      })
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  checkIfNotExternalSM() {
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  getOrderHistoryFromDB(quoteId) {
    this.loadingOrderHistory = true;
    this.orderService.getOrderHistoryFromDB(quoteId)
      .subscribe((OrderHistory) => {
        this.isOrderHistory = true;
        this.orderhistory = OrderHistory;
        this.loadingOrderHistory = false;
      })
  }

  openErrorPopup(msg, msgTitle = null) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg,
      title: msgTitle
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  getSubscriptionDetails(quoteId) {
    this.subscribeService.getSubscriptionDetails(quoteId)
      .subscribe((details) => {
        this.subscribeDetails = details
        this.checkSubscriptionStatus();
      })
  }

  successMsgFunc(msg) {
    this.userMsg.setClearSuccessTimeout();
    this.userMsg.setSuccessMsg(msg);
  }

  getOrderTags(quoteId) {
    this.orderService.getOrderTags(quoteId)
      .subscribe((ordTags) => {
        if (ordTags) this.orderTags = ordTags;
        else this.orderTags = [];
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  checkSubscriptionStatus() {
    if (!(_.isEmpty(this.subscribeDetails))) {
      this.subscriptionStatus = this.subscribeDetails.some((x) => 
      (this.auth.getUserId() == x.Subscribed_User_ID && x.Subscription_Status == CONSTANTS.subscriptionStatus.active))
    } else this.subscriptionStatus = false;
  }

  isPricingDone() {
    if (this.quoteDetails.Order_Status > CONSTANTS.orderStatus.received) return true
    return null;
  }

  getDocs(quoteId) {
    this.orderService.getOrderDocuments(quoteId)
      .subscribe((data) => {
        this.documents = data;
        this.clientDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.clientDocs);//Client Type
        this.adminDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs).map((item) => {
          if(item.Doc_Cat_ID){
            let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
            item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
          }
          else item.Category = 'Click to categorize'
          return item;
        });;//Admin Type
        this.internalDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.internalDocs).map((item) => {
          if(item.Doc_Cat_ID){
            let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
            item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
          }
          else item.Category = 'Click to categorize'
          return item;
        });//Internal Type        
        if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(quoteId);
      }, (err) => {
        console.log(err);
      })
  }

  openTagMenu() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        for (var i = 0, len = activeTags.rows.length; i < len; i++) {
          if (this.orderTags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1) {
            if(activeTags.rows[i].Tag_ID != this.config.getNewClientTagId() && activeTags.rows[i].Tag_ID != this.config.getPriorityClientTagId() 
            && activeTags.rows[i].Tag_ID != this.config.getPrePaidTagId() && activeTags.rows[i].Tag_ID != this.config.getPartiallyDeliveredTagId()
            && activeTags.rows[i].Tag_ID != this.config.getAwaitDlvryTagId() && activeTags.rows[i].Tag_ID != this.config.getAwaitPartDlvryTagId()) 
            this.availableTags.push(activeTags.rows[i]);
          }
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  menuClosed() {
    this.clearSearchTag();
    this.associatedTags = [];
    this.availableTags = [];
  }

  buildAddress(model) {
    this.completeAddress = '';
    if (model.Property_Address_1) this.completeAddress += model.Property_Address_1
    if (model.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += model.Property_Address_2
    }
    if (model.Property_City) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ', '
      this.completeAddress += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += ' '
      this.completeAddress += model.Property_ZipCode
    }
    this.constructLink();
  }

  constructLink() {
    this.netrOnlineLink += this.quoteDetails.Property_State_Abbr + '/county/' + this.fetchCounty()
  }

  fetchCounty() {
    let county = this.quoteDetails.Property_County.toLowerCase().split(' ').join('_');
    return county;
  }

  getOrderNotes(quoteId) {
    this.orderService.getOrderNotes(quoteId)
      .subscribe((notes) => {
        console.log("notes", notes);
        notes.forEach(note => {
          if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes) this.orderNotes = note;
          if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) this.orderIns = note;
        })
      }, (err) => {
        console.log(err);
      })
  }

  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  initializeProgressBar() {
    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(0);
    this.progressServ.setProgressState((0/2) * 100);
    this.progressServ.setBarType(1);
    this.progressServ.setTotalStages(2);
  }

  onTabChange(event) {
    if (event.index == 0 && !this.isChecklist) this.getChecklistFromGsheet(this.quoteId);
    else if (event.index == 1 && !this.isCountyInfo) this.getCountyInfoFromGsheet();
    else if (event.index == 2 && !this.isClientInfo) this.getClientInfoFromGsheet();
    else if (event.index == 3 && !this.isRelatedSearches) this.getRelatedSerarchOrders();
    else if (event.index == 4 && !this.isBillingInfo) this.getBillingInfoFromGsheet();
    else if (event.index == 5 && !this.isOrderHistory) if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
    else if (event.index == 6 && !this.isETAChange) this.getETAChangeReasonsById(this.quoteId);
    else if (event.index == 1 && !this.isSearchInfo) this.getSearchInfo();
  }

  getChecklistFromGsheet(quoteId) {
    this.loadingCheckList = true;
    this.orderService.getChecklistFromGsheet(quoteId)
      .subscribe((checkList) => {
        this.isChecklist = true;
        this.gSheetChecklist = checkList;
        this.loadingCheckList = false;
      }, (err) => {
        this.isChecklist = true;
        this.gSheetChecklist = [];
        this.loadingCheckList = false;
        // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getCountyInfoFromGsheet() {
    if(this.quoteDetails && (this.quoteDetails.Property_State_Abbr)){
    this.loadingCountyInfo = true;
      this.orderService.getCountyInfoFromGsheet(this.quoteDetails.Property_State_Abbr)
      .subscribe((countyInfo) => {
        this.isCountyInfo = true;
        this.gSheetCountyInfo =  countyInfo ? countyInfo : {};
        this.loadingCountyInfo = false;
      }, (err) => {
        this.isCountyInfo = true;
        this.gSheetCountyInfo = {};
        this.loadingCountyInfo = false;
        // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else{
      this.isCountyInfo = true;
    }
   
  }

  getClientInfoFromGsheet() {
    if (this.quoteDetails.Organization_ID) {
      this.loadingClientInfo = true;
      this.orderService.getClientInfoFromGsheet(this.quoteDetails.Organization_ID)
        .subscribe((clientInfo) => {
          this.isClientInfo = true;
          this.gSheetClientInfo = clientInfo[0] ? clientInfo[0] : {};
          this.loadingClientInfo = false;
        }, (err) => {
          this.isClientInfo = true;
          this.gSheetClientInfo = {};
          this.loadingClientInfo = false;
          // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else this.isClientInfo = true;

  }

  getSearchInfo() {
    this.loadingSearchInfo = true;
    this.orderService.getSearchInfo({ Property_County: this.quoteDetails.Property_County, Property_State_Abbr: this.quoteDetails.Property_State_Abbr })
      .subscribe((info) => {
        if(info.length > 0) this.gSheetSearchInfo = info[0];
        else this.gSheetSearchInfo = {};
        this.loadingSearchInfo = false;
      }, (err) => {
        this.loadingSearchInfo = false;
      })
  }

  checkIfDataDepthIsEmpty(){
    return Object.keys(this.gSheetSearchInfo).length === 0 && this.gSheetSearchInfo.constructor === Object

  }

  getBillingInfoFromGsheet() {
    if (this.quoteDetails.Organization_ID) {
      this.loadingBillingInfo = true;
      this.orderService.getBillingInfoFromGsheet(this.quoteDetails.Organization_ID)
        .subscribe((billingInfo) => {
          this.isBillingInfo = true;
          this.gSheetBillingInfo = billingInfo[0] ? billingInfo[0] : {};
          this.loadingBillingInfo = false;
        }, (err) => {
          this.isBillingInfo = true;
          this.gSheetBillingInfo = {};
          this.loadingBillingInfo = false;
          // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else this.isBillingInfo = true;
  }

  getRelatedSerarchOrders() {
    this.loadingRelatedSearches = true;
    if (this.quoteDetails.Organization_ID && this.quoteDetails.Organization_ID == this.config.getQualiaOrgId()) this.quoteDetails.isQualiaOrder = true;
    else this.quoteDetails.isQualiaOrder = false;
    this.orderService.getRelatedSerarchOrders(this.quoteDetails)
      .subscribe((relatedOrds) => {
        this.isRelatedSearches = true;
        this.ordsWithSameAddress = relatedOrds.ordsWithSameAddress;
        this.ordsWithSameParcel = relatedOrds.ordsWithSameParcel; // PT-3598
        this.ordsWithSameSubdivision = relatedOrds.ordsWithSameSubdivision; // PT-3598
        this.ordsWithSimilarCountyOrZip = relatedOrds.ordsWithSimilarCountyOrZip;
        this.ordsWithSameClient = relatedOrds.ordsWithSameClient;
        this.loadingRelatedSearches = false;
      }, (err) => {
        this.isRelatedSearches = true;
        this.ordsWithSameAddress = [];
        this.ordsWithSameParcel = []; // PT-3598
        this.ordsWithSameSubdivision = []; // PT-3598
        this.ordsWithSimilarCountyOrZip = [];
        this.ordsWithSameClient = [];
        this.loadingRelatedSearches = false;
        // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getETAChangeReasonsById(quoteId) {
    this.loadingETAChange = true;
    this.orderService.getETAChangeReasonsById(quoteId)
      .subscribe((etaChgReasons) => {
        this.isETAChange = true;
        this.etaChangeReasons = etaChgReasons;
        this.loadingETAChange = false;
      }, (err) => {
        this.isETAChange = true;
        this.etaChangeReasons = [];
        this.loadingETAChange = false;
      })
  }

  getOrderMessages(quoteId) {
    this.orderService.getOrderMessages(quoteId)
      .subscribe((messages) => {
        console.log("messages", messages);
        this.messages = messages;
        this.messages.forEach((message) => {
          this.isThreadedMessage(message)
        })
      }, (err) => {
        console.log(err);
      })
  }

  isThreadedMessage(msg) {
    if (msg.Parent_Message_ID && this.parentMsgId == msg.Parent_Message_ID) {
      msg.replyFlag = false;
    } else {
      this.parentMsgId = msg.Parent_Message_ID;
      msg.replyFlag = true;
    }
  }

  getTimeline(quoteId) {
    this.orderService.getOrderTimeline(quoteId)
      .subscribe((timeline) => {
        this.progressServ.setTimeline(timeline);
      }, (err) => {
        console.log(err);
      })
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
        if (this.newDocs[matchedIndex]) {
          this.newDocs[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.newDocs[matchedIndex].completed = Math.round(this.newDocs[matchedIndex].completed);
        }
      });
  }

  uploadDocsWithType(type) {
    if (type == CONSTANTS.orderDocumentType.adminDocs) {
      this.uploadType = CONSTANTS.orderDocumentType.adminDocs
      this.checkStatus();
    } else if (type == CONSTANTS.orderDocumentType.internalDocs) {
      this.uploadType = CONSTANTS.orderDocumentType.internalDocs
      this.checkStatus();
    }
  }

  checkStatus() {
    if ((this.quoteDetails.Order_Status == this.constants.orderStatus.cancelled)) {
      this.openErrorPopup(CONSTANTS.apiErrors.docQuoteUploadFail);
    }
    else {
      this.openFileSelector();
      //------> Below is the omitted condition to restrict file upload functionality as per User role. 
      //------> Left omitted for future utiisation.
      
      /*if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.openFileSelector();
      else {
        if (!((this.quoteDetails.Order_Status == this.constants.orderStatus.quotecompleted))) 
        else this.openErrorPopup(CONSTANTS.apiErrors.docQuoteUploadFail);
      }*/
    }
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  uploadDocument(event) {
    if (this.uploadType == CONSTANTS.orderDocumentType.adminDocs) this.selectedDocsTab = CONSTANTS.orderDocumentTabs.adminTab;
    else {
      if (this.checkIfNotExternalSM()) this.selectedDocsTab = CONSTANTS.orderDocumentTabs.internalTab;
      else this.selectedDocsTab = CONSTANTS.orderDocumentTabs.adminTab;
    }
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      var docType = this.uploadType == CONSTANTS.orderDocumentType.adminDocs ? CONSTANTS.orderDocumentType.adminDocs : CONSTANTS.orderDocumentType.internalDocs;
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          var tempDocId = UUID.UUID();
          this.newDocs.push({ docId: tempDocId, name: event.target.files[i].name, completed: 0, type: docType })
          // fileUploads.push(this.uploadTagDocToS3AndUpdatePath(tempDocId, event.target.files[i], docType, this.tagDocumentName, this.otherDocsTag))
          fileUploads.push(this.uploadToS3AndUpdatePath(tempDocId, event.target.files[i], docType))
        }
        Promise.all(fileUploads)
          .then((files) => {
            //this.newDocs = this.newDocs.filter(function (document) { return !_.findWhere(files, document)});
            this.removeCompletedDocs(files)
            var succesFiles = files.filter((file) => file !== undefined);
            if (succesFiles.length > 0) {
              this.getDocs(this.quoteId);
              if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
            }
          })
          .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
      }
      else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    }
    else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile)
    }
  }

  validateFilenames(event) {
    console.log("selected files", event.target.files);
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  updateOrderDocsType(doc, docType) {
    if(docType == this.constants.orderDocumentType.internalDocs){
      this.dialog.open(DocCategoryComponent, { data:  {
        list: this.documentCategories,
        file: { name: doc.Document_Original_Name }
      }})
      .afterClosed()
      .subscribe((category) => {
        let cat = category ? category : this.getDefaultCategory();
        this.orderService.updateOrderDocsWithType(doc.Order_Document_ID, docType, cat)
        .subscribe((data) => {
          this.getDocs(this.quoteId);
          if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
        })
      })
    }
    else {
      this.orderService.updateOrderDocsWithType(doc.Order_Document_ID, docType, null)
      .subscribe((data) => {
        this.getDocs(this.quoteId);
        if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
      })
    }    
  }

  openDocCategory(doctype, info){
    if(doctype == this.constants.orderDocumentType.adminDocs){      
      return this.dialog.open(DocCategoryComponent, {data: info}).afterClosed()
      .toPromise()
      .then((result) => result)
    }
    else return Promise.resolve(null);
  }

  uploadToS3AndUpdatePath(tempDocId, file, docType) {
    return this.s3Service.onManagedUpload(file, this.quoteId)
      .then((data) => {
        return this.openDocCategory(docType, {
          list: this.documentCategories,
          file: file
        })
        .then((result) => {
          let cat = result ? result : this.getDefaultCategory();
          return this.s3Service.updateDocumentPath(this.quoteId,
            this.quoteDetails.Property_ID,
            file,
            data.Key,
            this.extractFilename(data.Key),
            docType,
            cat
          )
            .then(() => { return { docId: tempDocId, name: file.name, completed: 100, type: docType } })
            .catch((err) => { throw err; })
        })        
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  getNewPippinDouments() {
    return this.newDocs.filter((docs) => docs.type == CONSTANTS.orderDocumentType.adminDocs).map((item) => {
      if(item.Doc_Cat_ID){
        let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
        item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
      }
      else item.Category = 'Click to categorize'
      return item;
    });;
  }

  getNewInternalDouments() {
    return this.newDocs.filter((docs) => docs.type == CONSTANTS.orderDocumentType.internalDocs);
  }

  checkForRoleAdmin() {
    if ((Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) || (Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)) return true;
    else return false;
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  goBack() {
  //   if(this.routingState.getPrevUrl()==="/quote/request/step3"){
  //     this.router.navigate(['/orders/new']);
  // }else{
  //   this.location.back();
  // }
    // console.log("last Loc: " + this.routingState.getPrevUrl())
    let arrayHistory = this.routingState.getHistory()
    let startingIndex = _.findIndex(arrayHistory, (x) => x.includes('quotes/' + this.quoteDetails.Order_ID + '/view'));
    let lastIndex = _.findLastIndex(arrayHistory, (x) => x.includes('quotes/' + this.quoteDetails.Order_ID + '/view'))
    arrayHistory.splice(startingIndex, lastIndex + 1)
    let lastLoc
    if (arrayHistory.length) {lastLoc = arrayHistory[arrayHistory.length - 1];}
    else {return this.router.navigate(['/orders/new'])}
    if (this.routingState.getPrevUrl() && (
      this.routingState.getPrevUrl().includes("accept") ||
      this.routingState.getPrevUrl().includes("login") ||
      this.routingState.getPrevUrl().includes("auth/success") ||
      this.routingState.getPrevUrl().includes("view") ||
      this.routingState.getPrevUrl().includes("quote/request/step3")||
      this.routingState.getPrevUrl().includes("step3")
    )) {
      // console.log("step 2")
      if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) {return this.router.navigate(['/orders/inprocess'])}
      else {return this.router.navigate(['/orders/new'])}
    }
    else if (lastLoc) {
      if (lastLoc.indexOf("?") > 0) {
        let lastLocation = lastLoc.slice(0, lastLoc.indexOf("?"));
        return this.router.navigate([lastLocation]);
      } else {
        if(lastLoc.includes("step3")){
          return this.router.navigate(['/orders/requestquote'])  
        }else if(lastLoc.includes("view")){
          return this.router.navigate(['/index'])
        }
        return this.router.navigate([lastLoc])}
    }
  }

  cancelQuote() {
    this.openCancelDialog().afterClosed()
    .subscribe((res) => {
      if (res) {
        if (res && res.state) {
          this.QuoterequestService.cancelQuote(this.quoteId, res.comment, this.quoteDetails.Customer_ID)
          .subscribe((data) => {
            this.getQuoteDetails(this.quoteId);
            this.getOrderMessages(this.quoteId);
            this.getOrderTags(this.quoteId);
            this.getOrderHistoryFromDB(this.quoteId);
            this.getSubscriptionDetails(this.quoteId);
          } , (err) => {
                console.log(err);
                this.openErrorPopup(CONSTANTS.apiErrors.ordercancel);
              })
        }else console.log("Dialog closed")
      }
    })
  }

  openMessageInterface() {
    let disposable = this.matDialog.open(MessageComponent, {data:{
      title: 'Send Message',
      msgSendType: CONSTANTS.messageSendStatus.orderMessage
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res) {
          if (!res.checked) this.sendMessage(res.messageText)
          else this.sendInternalMessage(res.messageText, CONSTANTS.messageType.internalMessage)
        }
      });
  }

  sendMessage(text) {
    var data = {
      Message_Text: text,
      Customer_ID: this.quoteDetails.Customer_ID
    }
    this.orderService.sendMessage(this.quoteId, data, this.auth.getUserId())
      .subscribe((data) => {
        console.log(data);
        this.getOrderMessages(this.quoteId);
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  sendInternalMessage(text, msgType) {
    var data = {
      Message_Text: text,
      Message_Type: msgType
    }
    this.orderService.sendInternalMessage(this.quoteId, data, this.auth.getUserId())
      .subscribe((data) => {
        console.log(data);
        this.getOrderMessages(this.quoteId);
      }
        , (err) => {
          console.log(err);
          this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
        })
  }

  completeQuote() {
    this.getProductsForCreateOrder()
    this.openQuoteCompletePopup().afterClosed()
    .subscribe((res) => {
      if(res && res.state) {
        this.matDialog.open(ReviewCompleteQuoteComponent,
           {data:{ Order_Price: res.Order_Price, 
            Order_Estimated_Time: res.Order_Estimated_Time,
            quoteDetails: this.quoteDetails,
            orderInstructions: { Search_Instructions: this.orderNotes, Special_Instructions: this.orderIns }
          }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((result)=> {
          if(result && result.state) {
              res.notifyQuoteCompleteToClient = result.notifyEmailToClient;
              res.quoteCompleteClientMailBody = result.mailBody;
             this.QuoterequestService.completeQuoteReq(res)
            .subscribe((res) => {
              this.getQuoteDetails(this.quoteId);
              this.getOrderMessages(this.quoteId);
              this.getDocs(this.quoteId);
              this.getTimeline(this.quoteId);
              this.getOrderTags(this.quoteId);
              this.getSubscriptionDetails(this.quoteId);
            })
          }
        })       
      }
    })
    
  }

  openCancelDialog() {
    return this.matDialog.open(CancelOrderComponent, {data:{  quoteId: this.quoteDetails.Order_ID}, ...this.config.getDialogOptions()})
  }

  checkForCompleteStatus(){
    if(this.quoteDetails && (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.quotecompleted || this.quoteDetails.Order_Status == CONSTANTS.orderStatus.cancelled)) return true;
    else return false;
  }

  checkForCompleteStatusOnly(){
    if(this.quoteDetails && (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.quotecompleted )) return true;
    else return false;
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  openQuoteCompletePopup(){
    return this.matDialog.open(QuoteCompleteComponent, {data:{ quoteId: this.quoteDetails.Order_ID}, ...this.config.getDialogOptions()})
  }

  setProgressBarStatus(status) {
    var stage = this.getStage(status);
    this.progressServ.setStage(stage);
    if (stage == 4) this.progressServ.setProgressState((((stage - 1) / 4) * 100) - 1);
    else this.progressServ.setProgressState(((stage - 1) / 4) * 100);
  }

  getStage(status) {
    if (status < CONSTANTS.orderStatus.assigned) return 1;
    else if (status < CONSTANTS.orderStatus.confirmed) return 2;
    else if (status == CONSTANTS.orderStatus.confirmed) return 3;
    else if (status < CONSTANTS.orderStatus.quotecompleted) return 4;
    else return 5
  }

  removeTag(tag) {
    if(tag.Tag_ID == this.config.getNewClientTagId()) {
      this.openErrorPopup("New Client tags are set at the client level and cannot be removed at the quote level.");  
    } 
    else if(tag.Tag_ID == this.config.getPriorityClientTagId()) {
      this.openErrorPopup("Priority Client tags are set at the client level and cannot be removed at the order level.");  
    } 
    else if(tag.Tag_ID == this.config.getPrePaidTagId()) {
      this.openErrorPopup("Prepaid tags are set at the client level and cannot be removed at the quote level.");  
    } 
    else if(tag.Tag_ID == this.config.getAbsDoubleAssignTagId()) {
      this.openErrorPopup("This tag cannot be removed manually.");  
    } 
    else { 
      this.matDialog.open(ConfirmComponent, {data:{
        title: 'Remove Tag',
        message: 'Are you sure you want to remove ' + tag.Tag_Name + ' tag?'
      }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
        if (res) {
          var associatedTags = this.orderTags.filter((orderTag) => orderTag.Tag_ID != tag.Tag_ID);
          this.updateOrderTags(associatedTags);
        }
      });
    }
  }

  updateOrderTags(associatedTags) {
    this.orderService.updateOrderTags({ Order_ID: this.quoteDetails.Order_ID, Order_Tags: associatedTags })
      .subscribe(() => {
        if (this.matMenuTrigger) this.matMenuTrigger.closeMenu();
        this.getOrderTags(this.quoteId);
        if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
      }, (err) => {
        console.log("err", err);
      })
  }

  previewFile(orderDocId) {
    this.fileService.preview(orderDocId)
      .subscribe(
        (userFile) => {
          var link = document.createElement('a');
          document.body.appendChild(link);
          link.target = '_blank';
          link.href = userFile.Link;
          link.click();
          document.body.removeChild(link);
        },
        (err) => {
          console.log(err);
          this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
  }

  checkStatusAndDeleteFile(filename, orderDocId) {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.deleteFile(filename, orderDocId);
    else {
      if (!((this.quoteDetails.Order_Status == this.constants.orderStatus.quotecompleted)
        || (this.quoteDetails.Order_Status == this.constants.orderStatus.cancelled))) this.deleteFile(filename, orderDocId)
      else this.openErrorPopup(CONSTANTS.apiErrors.docQuoteDeleteFail);
    }
  }

  deleteFile(filename, orderDocId) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + filename + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.fileService.delete(orderDocId, this.auth.getUserId())
            .subscribe(
              (data) => {
                this.getDocs(this.quoteId);
                if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
              },
              (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.docdelete);
              })
        } else {
          console.log("Closed", res)
        }
      });
  }

  loadOrderPriorityData(data) {
    if (data.Order_Priority == CONSTANTS.orderPriority.level2) {
      this.isRushOrder = true;
    } else {
      this.isRushOrder = false;
    }
  }


  convertToOrder() {
    this.quoteDetails.Order_Mode_Type_ID = 1;
    this.orderService.getOrderProductEntries(this.quoteDetails.Order_ID)
    .subscribe((orderData) => {
      if(orderData){ 
        this.quoteDetails.isProductAvailable= true;
        this.quoteDetails.Product_ID = orderData.Product_ID;
        this.processQuoteConversion({ orderDetails : this.quoteDetails})
      }
      else {
        // this.quoteDetails.Order_Source_Type_ID = 7;
        this.quoteDetails.isProductAvailable = false;
        let disposable = this.matDialog.open(ConvertOrderComponent, {data:{
          quoteDetails: this.quoteDetails,
          productDetails: this.products
        }}).afterClosed().subscribe((res) => {
          if (res && res.state) this.processQuoteConversion(res)            
        });
      }
    })  
    
}

processQuoteConversion(res){
  this.newOrder.checkIfQualaiUser(this.quoteDetails.Customer_ID)
  .subscribe((data) => {
    res.orderDetails.Qualia_Fee_Add = data.status && this.quoteDetails.Order_Qualia_Internal_ID ? 1: 0;
    if(this.quoteDetails.Organization_ID) {
      this.organizationService
      .getOrganizationDetails(this.quoteDetails.Organization_ID)
      .subscribe((orgDetails) => {
          // if(orgDetails) {
          //   if(orgDetails.Organization_Type == 1)  res.orderDetails.Order_Source_Type_ID = 4;
          //   else if(orgDetails.Organization_Type == 4) res.orderDetails.Order_Source_Type_ID = 3;
          //   else if(orgDetails.Organization_Type == 8) res.orderDetails.Order_Source_Type_ID = 2;
          //   else res.orderDetails.Order_Source_Type_ID = 7;
          // }
           //check for qualia internal id for order source
           if(res.orderDetails.Order_Source_Type_ID && res.orderDetails.Order_Source_Type_ID == 4 && !this.quoteDetails.Order_Qualia_Internal_ID) res.orderDetails.Order_Source_Type_ID = 7;

              this.newOrder.convertToOrderFromQuotes(res.orderDetails)
          .subscribe((data) => {
            this.router.navigate(['/orders/'+ this.quoteDetails.Order_ID + '/view']);
          })

        })
    }
    else{
      if(data.status && this.quoteDetails.Order_Qualia_Internal_ID)
        res.orderDetails.Order_Source_Type_ID = 4;
      else
        res.orderDetails.Order_Source_Type_ID = 7;
      this.newOrder.convertToOrderFromQuotes(res.orderDetails).subscribe((data)=>{
        this.router.navigate(['/orders/'+ this.quoteDetails.Order_ID + '/view']);
      })
    }   
  })        
}


getProductsForCreateOrder() {
  let customerId = this.quoteDetails.Organization_ID ? this.quoteDetails.Organization_ID : this.quoteDetails.Customer_ID;
  this.orderService.getOrderProductsForClient(customerId)
    .subscribe((product) => {
      this.products = product;
    })
}

openSuccessDialog(title, msg) {
  let disposable = this.matDialog.open(SuccessComponent, {data:{
    title: title,
    message: msg
  }}).afterClosed()
    .subscribe((res) => {
      if (res) this.getOrderMessages(this.quoteId);
    });
}

checkAdminType() {
  if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
  else return true;
}

checkForMenuActive(){
  if(this.config.getMySidebar()) return true;
  else return false;
}

isSuperAdmin(){
  if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
  else if(this.auth.getUserId() == environment.superAdminId) return true;
  else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
  else return false;
}

editQuote() {
  this.router.navigate(['/quotes/' + this.quoteDetails.Order_ID + '/edit']);
}

checkForPermission() {
  if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.isAllowed = true;
  else {
    if (!this.quoteDetails.Organization_ID) this.isAllowed = true;
    else {
      var data = {
        Organization_ID: this.quoteDetails.Organization_ID,
        Admin_ID: this.auth.getUserId()
      }
      this.orderService.checkOrderPermission(data) 
        .subscribe(allow => {
          this.isAllowed = allow;           
        }, (err) => {
          console.log(err);
        })
    }
  }
}

orderAssignee(order){
  var data = {};
  if(order.Organization_ID) data['Organization_ID'] = order.Organization_ID;
    this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
      let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
        title: 'Assign Quote',
        message: {
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
          Current_Admin: order.Order_Admin_ID
        }
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) {
            let msgType;
            if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
            else {
              res.msgText = ""
              msgType = CONSTANTS.messageType.internalNotes;
            }
            if(res.admin && res.getTimeToCompl){
              this.orderService.changeQuoteAssignee(order.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
              .subscribe((data) => {
                this.getQuoteDetails(this.quoteId);
                this.getOrderHistoryFromDB(this.quoteId);
                this.getOrderMessages(this.quoteId);
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
            }
            else {
              this.orderService.unassignOrder(order.Order_ID ,res.msgText,msgType, order.Order_Status).subscribe((data)=>{  
                this.getQuoteDetails(this.quoteId);
                this.getOrderHistoryFromDB(this.quoteId);
                this.getOrderMessages(this.quoteId);              
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
            })
            }
          }          
    }, (err) => { console.log(err); })
  })
}

  downloadFile(filename, orderDocId) {
    this.fileService.download(orderDocId)
      .subscribe(
        (userFile) => {
          if (userFile) {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.download = filename;
            link.href = userFile.Link;
            link.click();
            document.body.removeChild(link);
          }
        },
        (err) => {
          console.log(err);
          this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
  }

  handleDocCategoryChange(doc){  
    this.dialog.open(DocCategoryComponent, { data:  {
      list: this.documentCategories,
      file: { name: doc.Document_Original_Name , doc_cat_id: doc.Doc_Cat_ID}
    }})
    .afterClosed()
    .subscribe((category) => {
      let cat = category ? category : this.getDefaultCategory();
      if(category && cat != doc.Doc_Cat_ID){           
        this.orderService.updateDocumentCategory(doc.Order_Document_ID, cat).subscribe(() => {
          this.getDocs(this.quoteDetails.Order_ID);
        }, ()=> {
          this.openErrorPopup("Error updating the document category");
        })
      }            
    })
  }

  getDefaultCategory(){
    let defItem = this.documentCategories.find((item) => item.Category.toLowerCase() == 'misc')
    console.log("defItem", defItem);
    return defItem ? defItem.Id: null;
  }

  getDataDepthInfo() {
    this.loadingSearchInfo = true;
    this.orderService.getDataDepthInfo({Property_State_Abbr: this.quoteDetails.Property_State_Abbr })
      .subscribe((info) => {
        this.dataDepthYears = info[0]["State Statute"][0]
        // if(info.length > 0) this.dataDepth = info[0];
        // else this.gsheetSearchInfo = {};
        this.loadingSearchInfo = false;
      }, (err) => {
        this.loadingSearchInfo = false;
      })
  }
  changeQuoteStatus(){
    var currentQuoteStatus = this.quoteDetails.Order_Status;
    var quoteStatusList = []
    var quoteStatus = {};
    // if(this.quoteDetails && this.quoteDetails.Order_ID){
    //   this.getAllOrderStatus(this.quoteDetails.Order_ID);
    // }
    if(currentQuoteStatus != CONSTANTS.orderStatus.received){
      quoteStatus['text'] = "Received";
      quoteStatus['val'] = CONSTANTS.orderStatus.received;
      quoteStatusList.push(quoteStatus);
    }
    let disposable = this.matDialog.open(ChangeOrderStatusComponent, {data:{
      title: 'Change Quote Status',
      message: quoteStatusList
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.QuoterequestService.changeQuoteStatus(this.quoteId, res)
            .subscribe((data) => {
              this.getOrderTags(this.quoteId);
              this.initializeProgressBar();
              this.getQuoteDetails(this.quoteId);
              this.getOrderMessages(this.quoteId);
              this.getTimeline(this.quoteId);
              this.getSubscriptionDetails(this.quoteId);
              if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
              // this.checkReadyBillingStatus();
            }, (err) => {
              console.log(err);
            })
        } else {
          console.log("Dialog closed")
        }
      });
  }

  goToClientPage() {
    this.router.navigate(['/client/'+this.quoteDetails.Customer_ID]);
  }

  goToCompanyPage() {
    this.router.navigate(['/organization/edit/'+this.quoteDetails.Organization_ID]);
  }

  getAbstractorList() {
    return this.abstractorService.getAbstractorList(this.quoteDetails.Order_ID, [['Abstrator_Cost', 'ASC'], ['Abstrator_Rating', 'DESC']])
  }

  openAbsQuoteModal(abstractors) {
    return this.matDialog.open(AbstractorQuoteComponent, {data:{
      title: 'Abstractors list',
      message: { Order: this.quoteDetails, Abstractors: abstractors }
    }, ...this.config.getDialogOptions()})
  }

  openAbsQuotePreview(selectedAbstractors) {
    let disposable = this.matDialog.open(AbsQuoteMessageComponent, {data:{
      message: {
        orderDetails: this.quoteDetails, selectedAbstractors: selectedAbstractors, instructions: { Search_Instructions: this.orderNotes, Special_Instructions: this.orderIns }
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          let Product_Description;
          if(res.selectedProductName) Product_Description = res.selectedProductName;
          else {
            Product_Description = this.quoteDetails.Product_Description;
            if(this.quoteDetails.Product_Description && Product_Description.toLowerCase().includes("plus")){
              var lastIndex = Product_Description.lastIndexOf(" ");
              Product_Description = Product_Description.substring(0, lastIndex);
            }
          }
          this.abstractorService.requestAbsQuote({
            Order_ID: this.quoteDetails.Order_ID,
            selectedAbstractors: selectedAbstractors,
            Message_Text: res.absQuoteMessage,
            Message_Subject: 'Pippin Title Order #'+ this.quoteDetails.Order_ID+' - ' +Product_Description+' Quote/ETA Request - '+this.quoteDetails.Property_County+' County, '+this.quoteDetails.Property_State_Abbr,
            fromEmailId:res.selectedEmailId
          })
            .subscribe((respones) => {
              this.successMsgFunc('Request abstractor quote sent successfully.')
              if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
              this.getOrderTags(this.quoteDetails.Order_ID);
            }, (err) => {
              this.openErrorPopup("Error while sending the request quote to the abstractor.");
            })
        }
      })
  }

  openNewAbsQuoteModal() {
    return this.matDialog.open(NewAbsQuoteComponent,{data:{
      message: {
        order: this.quoteDetails,
        Search_Instructions: this.orderNotes,
        Special_Instructions: this.orderIns,
        clientInfo : this.gSheetClientInfo
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => {
      if (res && res.state) {
        this.successMsgFunc('Request abstractor quote sent successfully.');
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.quoteId);
        this.getOrderTags(this.quoteDetails.Order_ID);
      }
    })
  }

  requestAbsQuote() {
    this.getAbstractorList().subscribe((data) => {
      if(data.length > 0) {
        this.openAbsQuoteModal(data).afterClosed()
          .subscribe((result) => {
            if (result) {
              if (result == -1) this.openNewAbsQuoteModal();
              else if (result.selectedAbstractors.length > 0) this.openAbsQuotePreview(result.selectedAbstractors);
              else console.log("No abs found");
            }
          })
      } else {
        this.openNewAbsQuoteModal();
      }
    }, (err) => {
      console.log(err);
      this.openErrorPopup("Error while fetching data.");
    })
  }

}
